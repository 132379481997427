import "./App.css";
import { BrowserRouter } from "react-router-dom";
import Router from "./pages/router";
import React, { useEffect } from "react";
import { GoogleOAuthProvider } from "@react-oauth/google";

declare const window: Window &
  typeof globalThis & { fbAsyncInit: () => void; FB: any };

const GOOGLE_CLIENT_ID =
  "667507656707-pbvneqlhn4e2gtciavutfkaj30j3h0fg.apps.googleusercontent.com"; // Thay thế bằng Google client ID của bạn

const App = () => {

  return (
    <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
      <BrowserRouter>
        <Router />
      </BrowserRouter>
    </GoogleOAuthProvider>
  );
};

export default App;
