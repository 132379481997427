// @ts-nocheck
import React from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { RootState } from "../redux/store";

const ProtectedRoute = ({ children }) => {
    const userInfo = useSelector(
        (state: RootState) => state.auth
      ) as any;
    if (!userInfo.isAuthenticated) {
      return <Navigate to="/    " replace />;
    }

    return children;
  };
export default ProtectedRoute;
