// tempProfileSlice.js

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  profile: null,
};

const tempProfileSlice = createSlice({
  name: 'tempProfile',
  initialState,
  reducers: {
    setTempProfile: (state, action) => {
      state.profile = action.payload;
    },
    clearTempProfile: (state) => {
      state.profile = null;
    },
  },
});

export const { setTempProfile, clearTempProfile } = tempProfileSlice.actions;
export default tempProfileSlice.reducer;
