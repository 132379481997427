import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";

const Dashboard = () => {
    const userInfo = useSelector(
      (state: RootState) => state.auth
    ) as any;

    const currentTenant = useSelector(
      (state: RootState) => state.tenant
    ) as any;

    return (
      <div>
        <h1>{currentTenant.name} Salon Dashboard</h1>
        <h1>User</h1>
        <h1>Email: {userInfo.user}</h1>
      </div>
    );
  };

  export default Dashboard;
