import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse, AxiosError } from 'axios';

const apiUrl = "http://api-int.easysalon.net";

// Create an Axios instance
const axiosInstance: AxiosInstance = axios.create({
  baseURL: apiUrl,
  timeout: 10000,
});

// Interceptor for request
axiosInstance.interceptors.request.use(
  (config: AxiosRequestConfig): any => {
    // You can modify the request config here, such as adding headers
    // config.headers['Authorization'] = `Bearer ${accessToken}`;
    return config;
  },
  (error: AxiosError) => {
    // Handle request error
    return Promise.reject(error);
  }
);

// Interceptor for response
axiosInstance.interceptors.response.use(
  (response: AxiosResponse): AxiosResponse => {
    // You can modify the response data here before it's returned to the caller
    return response;
  },
  (error: AxiosError) => {
    // Handle response error
    if (error.response) {
      // Handle HTTP error (e.g., 404, 500)
      console.error('HTTP Error:', error.response.status, error.response.data);
    } else if (error.request) {
      // Handle network error (e.g., no internet connection)
      console.error('Network Error:', error.message);
    } else {
      // Handle other errors
      console.error('Error:', error.message);
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
