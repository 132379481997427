
import React, { useEffect } from "react";
import { GoogleLogin } from "@react-oauth/google";
import FacebookLogin from "react-facebook-login";
import { Auth } from "aws-amplify";
import useGoogleLogin from "../../hooks/useGoogleLogin";
import "../../styles/TrialSignUp.css";
import { setTempProfile } from "../../redux/slices/tempUserSlice";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import useFacebookLogin from "../../hooks/useFacebookLogin";
import { getTenant } from "../../services/tenantService";
import { setUser } from "../../redux/slices/authSlice";
import { setTenant } from "../../redux/slices/tenantSlice";

const TrialSignUp = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { handleGoogleLogin } = useGoogleLogin();
  const { handleFacebookLogin } = useFacebookLogin();
  useEffect(() => {
    // Initialize Facebook SDK here from above
  }, []);

  const handleGoogleSuccess = async (response: any) => {
    console.log("🚀 ~ file: index.tsx:41 ~ handleGoogleSuccess ~ response:", response)
    const googleProfile = await handleGoogleLogin(response);
    try {
      await Auth.signIn(googleProfile.email, response.clientId);
      const user = await Auth.currentAuthenticatedUser();
      const tenantId = user.attributes["custom:tenantId"]
      const tenant = await getTenant(tenantId);
      dispatch(setUser(user));
      dispatch(setTenant(tenant));
      const session = await Auth.currentSession()
      const sessionItems = Object.values(session.getIdToken().payload);
      sessionItems.forEach((item) => {
        const itemIdx = sessionItems.indexOf(item);
        window.sessionStorage.setItem(Object.keys(session.getIdToken().payload)[itemIdx], item);
      })
      navigate('/dashboard');
    } catch (e){
      dispatch(setTempProfile(googleProfile));
      navigate('/tenant-registration');
    }
  };

  const handleFacebookSuccess = async (response: any) => {
    const facebookProfile = await handleFacebookLogin(response);
    try {
      await Auth.signIn(facebookProfile.email, response.userID);
      const user = await Auth.currentAuthenticatedUser();
      const tenantId = user.attributes["custom:tenantId"]
      const tenant = await getTenant(tenantId);
      dispatch(setUser(user));
      dispatch(setTenant(tenant));
      const session = await Auth.currentSession()
      const sessionItems = Object.values(session.getIdToken().payload);
      sessionItems.forEach((item) => {
        const itemIdx = sessionItems.indexOf(item);
        window.sessionStorage.setItem(Object.keys(session.getIdToken().payload)[itemIdx], item);
      })
      navigate('/dashboard');
    } catch (e){
      dispatch(setTempProfile(facebookProfile));
      navigate('/tenant-registration');
    }

  };

  return (
    <div className="App">
      <h1>Sign up your trial now</h1>

      <div className="GoogleLoginContainer">
        <GoogleLogin
          onSuccess={handleGoogleSuccess}
          onError={() => {
            console.log('Login Failed');
          }}
          useOneTap={true}
          type="standard"
        />
      </div>

      <div className="FacebookLoginContainer">
        <FacebookLogin
          appId="278263001713090"
          autoLoad={false}
          fields="name,email,picture"
          callback={handleFacebookSuccess}
          scope="openid"
        />
      </div>
    </div>
  );
};

export default TrialSignUp;
