/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "region": "us-west-2",
    "identityPoolId": "us-west-2:54b0d7d7-098c-4ec7-a6d9-7cb893ca78f8",
    "aws_cognito_region": "us-west-2",
    "userPoolId": "us-west-2_bllQaatTs",
    "userPoolWebClientId": "447fp92dae4ck7rvma0g634po7",
    "authenticationFlowType": "USER_PASSWORD_AUTH",
    "oauth": {
        "domain": "appeasysalon75dc68f9-75dc68f9-dev.auth.us-west-2.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "http://localhost:3000/,https://app.easysalon.net/",
        "redirectSignOut": "http://localhost:3000/login/,https://app.easysalon.net/login/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [
        "GOOGLE"
    ],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ]
};


export default awsmobile;
