// TenantRegistration.js

import React, { useState } from "react";
import { useSelector } from "react-redux";
import useRegisterUser from "../../hooks/useRegisterUser"; // Import your hook for Cognito registration
import { RootState } from "../../redux/store";
import { createTenant } from "../../services/tenantService";
import { useNavigate } from "react-router-dom";
// import { clearTempProfile } from './tempProfileSlice'; // Import the action to clear the temp profile

const SalonOnboarding = () => {
  const { handleRegisterUser } = useRegisterUser();
  const navigate = useNavigate();
  const tempProfile = useSelector(
    (state: RootState) => state.tempProfile.profile
  );

  const [tenantName, setTenantName] = useState("");
  const [error, setError] = useState("");
  const [registrationSuccess, setRegistrationSuccess] = useState(false); // Added state for registration success

  const handleRegister = async () => {
    if (!tenantName) {
      setError("Please enter a tenant name.");
      return;
    }

    // Call the registerCognito hook to create the Cognito user with the tenant ID and Google profile
    try {
      const newTenant = await createTenant({ name: tenantName });
      await handleRegisterUser(Number(newTenant?.id), tempProfile);
      setRegistrationSuccess(true);
      // Registration successful, you can clear the temp profile data
      //   dispatch(clearTempProfile());
      // Redirect the user or perform any other actions
    } catch (err) {
      setError("Error registering the user.");
    }
  };

  const navigateToLogin = () => {
    // 👇️ navigate to /contacts
    navigate('/login');
  };

  return (
    <div>
      <h2>Salon Registration</h2>
      {registrationSuccess ? ( // Conditional rendering for success message
      <div>
        <p>Salon and user successfully registered!</p>
        <button onClick={navigateToLogin}>Login</button>
      </div>
      ) : (
        <div>
          <div>
            <label htmlFor="tenantName">Enter your salon name:</label>
            <input
              type="text"
              id="tenantName"
              value={tenantName}
              onChange={(e) => setTenantName(e.target.value)}
            />
          </div>
          {error && <p className="error">{error}</p>}
          <button onClick={handleRegister}>Register</button>
        </div>
      )}
    </div>
  );
};

export default SalonOnboarding;
