import React from "react";
import { useNavigate } from "react-router-dom";

const Home = () => {
    const navigate = useNavigate();
    const navigateToLogin = () => {
        navigate('/login');
      };

      const navigateToTrialSignup = () => {
        navigate('/register');
      };
    return (
      <div>
        <h1>Welcome to EasySalon!</h1>
        <button onClick={navigateToLogin}>Login</button>
        <button onClick={navigateToTrialSignup}>Start with the trial</button>
      </div>
    );
  };

  export default Home;
