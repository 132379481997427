import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  id: null,
  name: null
};

const tenantSlice = createSlice({
  name: 'tenant',
  initialState,
  reducers: {
    setTenant: (state, action) => {
      state.id = action.payload.id;
      state.name = action.payload.name;
    },
    // clearTempProfile: (state) => {
    //   state.profile = null;
    // },
  },
});

export const { setTenant } = tenantSlice.actions;
export default tenantSlice.reducer;
