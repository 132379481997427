import React, { useEffect, useState } from "react";
import { setTempProfile } from "../../redux/slices/tempUserSlice";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { useNavigate } from "react-router-dom";

const RegisterEmail = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const tempProfile = useSelector(
      (state: RootState) => state.tempProfile.profile
    ) as any;


    const handleConfirm = () => {
      dispatch(setTempProfile({
        ...tempProfile, email: email,
      }));
      navigate('/tenant-registration')
    }

    return (
        <div>
        <label htmlFor="email">Enter your email:</label>
        <input
          type="text"
          id="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <button onClick={handleConfirm}>Register</button>
      </div>
    )
}

export default RegisterEmail;
