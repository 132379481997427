import jwt_decode from "jwt-decode";

function useGoogleLogin() {
  const handleGoogleLogin = async (response: any) => {
    try {
      const decodedToken: any = jwt_decode(response.credential);
      const { email, name, picture } = decodedToken;
      return { email, name, picture, originId: response.clientId, providerType: 'google' };
    } catch (error) {
      throw error;
    }
  };

  return { handleGoogleLogin };
}

export default useGoogleLogin;
