function useFacebookLogin() {
  const handleFacebookLogin = async (response: any) => {
    try {
      const { name, picture, userID } = response;
      return { email: `${response.userID}@facebook.com`, name, picture, originId: userID, providerType: 'facebook' };
    } catch (error) {
      throw error;
    }
  };

  return { handleFacebookLogin };
}

export default useFacebookLogin;
