import { Tenant } from '../types/tenant.types';
import axiosInstance from './axiosInstance';

// Define the base URL for the tenant-related API endpoints
const tenantBaseUrl = '/tenants';

// Function to fetch a list of tenants
export async function getTenants(): Promise<Tenant[]> {
  try {
    const response = await axiosInstance.get<Tenant[]>(tenantBaseUrl);
    return response.data;
  } catch (error) {
    throw error;
  }
}

// Function to create a new tenant
export async function createTenant(newTenant: Tenant): Promise<Tenant> {
  try {
    const response = await axiosInstance.post<Tenant>(tenantBaseUrl, newTenant);
    return response.data;
  } catch (error) {
    throw error;
  }
}

// Function to update an existing tenant
export async function updateTenant(updatedTenant: Tenant): Promise<Tenant> {
  try {
    const response = await axiosInstance.put<Tenant>(
      `${tenantBaseUrl}/${updatedTenant.id}`,
      updatedTenant
    );
    return response.data;
  } catch (error) {
    throw error;
  }
}

// Function to get an existing tenant
export async function getTenant(id: number): Promise<Tenant> {
  try {
    const response = await axiosInstance.get<Tenant>(
      `${tenantBaseUrl}/${id}`
    );
    return response.data;
  } catch (error) {
    throw error;
  }
}

// Function to delete a tenant by ID
export async function deleteTenant(tenantId: number): Promise<void> {
  try {
    await axiosInstance.delete(`${tenantBaseUrl}/${tenantId}`);
  } catch (error) {
    throw error;
  }
}
