import React from "react";
import { routerType } from "../types/route.types";
import Home from "./Home";
import Login from "./Login";
import TrialSignUp from "./SignUp";
import SalonOnboarding from "./SalonOnboarding";
import RegisterEmail from "./SignUp/RegisterEmail";
import Dashboard from "./Dashboard";
import ProtectedRoute from "./protectedRouter";

const pagesData: routerType[] = [
  {
    path: "",
    element: <Home />,
    title: "home"
  },
  {
    path: "register",
    element: <TrialSignUp />,
    title: "Register"
  },
  {
    path: "tenant-registration",
    element: <SalonOnboarding />,
    title: "Register Your Business"
  },
  {
    path: "email-registration",
    element: <RegisterEmail />,
    title: "Register Your Email"
  },
  {
    path: "login",
    element: <Login />,
    title: "Login"
  },
  {
    path: "dashboard",
    element:
      <ProtectedRoute>
        <Dashboard />
      </ProtectedRoute>
    ,
    title: "Dashboard"
  }
];

export default pagesData;
