// registerCognito.js

import { Auth } from 'aws-amplify';

function useRegisterUser() {
    const handleRegisterUser = async (tenantId: number, googleProfile: any) =>{
        try {
            const { email, providerType, originId } = googleProfile;
            const signUpProfile = await Auth.signUp({
              username: providerType === 'google' ? email : `${originId}@facebook.com`,
              password: originId,
              attributes: {
                email: email,
                  "custom:tenantId"	: tenantId.toString(),
                  "custom:providerType": providerType,
                  "custom:originId"	: originId,
              },
            });

            // After successful registration, sign in the user (if needed)
            // const signInResponse = await Auth.federatedSignIn('google', googleProfile);

            return signUpProfile; // Return the sign-in response or other relevant data
          } catch (error) {
            throw error;
          }

    }
    return { handleRegisterUser };

}

export default useRegisterUser;
