import { configureStore } from '@reduxjs/toolkit';
import authReducer from './slices/authSlice';
import tempProfileReducer from './slices/tempUserSlice';
import tenantReducer from './slices/tenantSlice';
const store = configureStore({
  reducer: {
    auth: authReducer,
    tempProfile: tempProfileReducer,
    tenant: tenantReducer,
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false,
    })
});

export type RootState = ReturnType<typeof store.getState>

export default store;
