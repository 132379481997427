//@ts-nocheck
import React, { useEffect, useState } from 'react';
import { GoogleLogin } from "@react-oauth/google";
import FacebookLogin from "react-facebook-login";
import { Auth } from 'aws-amplify';
import jwt_decode from "jwt-decode";
import { getTenant } from '../../services/tenantService';
import { useDispatch } from 'react-redux';
import { setUser } from '../../redux/slices/authSlice';
import { setTenant } from '../../redux/slices/tenantSlice';
import { useNavigate } from 'react-router-dom';

const Login = () => {
  const [error, setError] = useState() as any;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    // const { user, tenantId } = userInfo;
    // const checkTenant = async () => {
    //   let currentTenant: Tenant;
    //   if (tenantId) {
    //     currentTenant = await getTenant(tenantId);
    //   }
    // }
    // checkTenant();
  }, [])

  const handleGoogleSuccess = async (response: any) => {
    const decodedToken: any = jwt_decode(response.credential);
    const { email } = decodedToken
    try {
      await Auth.signIn(email, response.clientId);
      const user = await Auth.currentAuthenticatedUser();
      const tenantId = user.attributes["custom:tenantId"]
      const tenant = await getTenant(tenantId);
      dispatch(setUser(user));
      dispatch(setTenant(tenant));
      const session = await Auth.currentSession()
      const sessionItems = Object.values(session.getIdToken().payload);
      sessionItems.forEach((item) => {
        const itemIdx = sessionItems.indexOf(item);
        window.sessionStorage.setItem(Object.keys(session.getIdToken().payload)[itemIdx], item);
      })

      navigate('/dashboard');
    } catch (e) {
      console.log("🚀 ~ file: index.tsx:51 ~ handleGoogleSuccess ~ e:", e)
      setError(e.message);
      window.sessionStorage.clear();
    }
  }

  const handleFacebookSuccess = async (response: any) => {
    try {
      await Auth.signIn(`${response.userID}@facebook.com`, response.userID);
      const user = await Auth.currentAuthenticatedUser();
      const tenantId = user.attributes["custom:tenantId"]
      const tenant = await getTenant(tenantId);
      dispatch(setUser(user));
      dispatch(setTenant(tenant));
      const session = await Auth.currentSession()
      const sessionItems = Object.values(session.getIdToken().payload);
      sessionItems.forEach((item) => {
        const itemIdx = sessionItems.indexOf(item);
        window.sessionStorage.setItem(Object.keys(session.getIdToken().payload)[itemIdx], item);
      })

      navigate('/dashboard');
    } catch (e) {
      setError(e.message);
      window.sessionStorage.clear();
    }
  }

  return (
    <div className="login-container">
      <h2>Login</h2>
      {error ? ( // Conditional rendering for success message
        <p>{error}</p>
      ) :
      <div className="App">
      <h1>Sign up your trial now</h1>

      <div className="GoogleLoginContainer">
        <GoogleLogin
          onSuccess={handleGoogleSuccess}
          onError={() => {
            console.log('Login Failed');
          }}
          useOneTap={true}
          type="standard"
        />
      </div>

      <div className="FacebookLoginContainer">
        <FacebookLogin
          appId="278263001713090"
          autoLoad={false}
          fields="name,email,picture"
          callback={handleFacebookSuccess}
          scope="openid"
        />
      </div>
      </div>}
    </div>
  );
};

export default Login;
